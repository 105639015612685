import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';
import {
  ApprovalState,
  BackgroundCheckState,
  CandidateInfo,
  PanelStates,
} from './types';

const initialState: BackgroundCheckState = {
  approvalStatus: ApprovalState.Unapplied,
  currentPanelKey: 'zeroState',
  currentChecks: [],
};

const backgroundCheckSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setApprovalStatus(state, action: PayloadAction<ApprovalState>) {
      state.approvalStatus = action.payload;
    },
    setCurrentPanelKey(state, action: PayloadAction<PanelStates>) {
      state.currentPanelKey = action.payload;
    },
    setCurrentChecks(state, action: PayloadAction<CandidateInfo[]>) {
      state.currentChecks = action.payload;
    },
  },
});

export const { setApprovalStatus, setCurrentPanelKey, setCurrentChecks } =
  backgroundCheckSlice.actions;

export { backgroundCheckSlice };

export const { reducer } = backgroundCheckSlice;
