import { toI18n } from 'util/i18n';

const DEFAULT_ROUTE = '/hiring/background_checks';

export const ROUTES = {
  STATUS: `${DEFAULT_ROUTE}/company/application`,
  APPLY: `${DEFAULT_ROUTE}/company/apply`,
  CHECKS: `${DEFAULT_ROUTE}/company/company_checks`,
};

export const SLICE_NAME = 'backgroundCheckSlice';

export const standardPriceList = [
  {
    primary: toI18n(
      'hr_pro.background_checks.standard.social_security_tracing'
    ),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.sex_offender_database'),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.county'),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.excluded_parties'),
  },
];

export const proPriceList = [
  {
    primary: toI18n('hr_pro.background_checks.pro.seven_year_unlimited_county'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.national_criminal_database'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.federal_district'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.employment'),
  },
];
