export enum ApprovalState {
  Unapplied = 'Unapplied',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export type PanelStates =
  | 'zeroState'
  | 'ownerForm'
  | 'companyForm'
  | 'thankYou'
  | 'emptyState'
  | 'runCheck'
  | 'steadyState';

export interface BackgroundCheckState {
  approvalStatus: ApprovalState;
  currentPanelKey: PanelStates;
  currentChecks: CandidateInfo[];
}

export interface PanelMethods {
  handleNextStep: () => Promise<void>;
}

export interface ApplicationPayload {
  name: string;
}

export interface ApplicationResponse {
  status: boolean;
}

export interface StatusResponse {
  status: string;
}

export interface CheckResponse {
  background_checks: CandidateInfo[];
}

export interface CandidateInfo {
  name: string;
  status: string;
  package_details: string;
  score: string;
  report: string;
}
